import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Clear any previous errors before submitting
            setError('');

            const response = await fetch('/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (response.ok) {
                const data = await response.json();
                // Store essential details in localStorage for session management
                localStorage.setItem('token', data.accessToken);
                localStorage.setItem('userRole', data.role);
                localStorage.setItem('userName', data.name);
                // Navigate to the main page after successful login
                navigate('/wireless-feasibility');
            } else {
                const errorText = await response.text();
                console.error('Login failed with status:', response.status, 'and message:', errorText);
                setError(errorText || 'Invalid email or password');
            }
        } catch (error) {
            console.error('Login failed', error);
            setError('An error occurred during login. Please try again later.');
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.loginBox}>
                <div style={styles.logoSection}>
                    <img src="/smartmaplogotext.png" alt="SmartMap Logo" style={styles.logo} />
                </div>
                <div style={styles.formSection}>
                    <form onSubmit={handleSubmit} style={styles.form}>
                        <input
                            type="email"
                            placeholder="Email@address.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={styles.input}
                            required
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={styles.input}
                            required
                        />
                        {error && <div style={styles.error}>{error}</div>}
                        <button type="submit" style={styles.button}>CONNECT</button>
                    </form>
                    <div style={styles.footer}>
                        <a href="/forgot-password" style={styles.footerLink}>Forgot your password?</a>
                        <span style={styles.footerSeparator}> - </span>
                        <a href="/contact" style={styles.footerLink}>Question?</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#ecf0f1',
    },
    loginBox: {
        display: 'flex',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '40px',
        maxWidth: '800px',
        width: '100%',
    },
    logoSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        paddingRight: '20px',
        borderRight: '1px solid #bdc3c7',
    },
    logo: {
        maxWidth: '150px',
        width: '100%',
        height: 'auto',
    },
    formSection: {
        flex: 2,
        paddingLeft: '20px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    input: {
        backgroundColor: '#ecf0f1',
        border: '1px solid #1abc9c',
        borderRadius: '4px',
        padding: '15px',
        marginBottom: '20px',
        color: '#2c3e50',
        fontSize: '16px',
    },
    button: {
        backgroundColor: '#61acb4',
        border: 'none',
        borderRadius: '4px',
        padding: '15px',
        color: 'white',
        fontSize: '16px',
        cursor: 'pointer',
    },
    footer: {
        marginTop: '20px',
        textAlign: 'center',
        color: '#7f8c8d',
    },
    footerLink: {
        color: '#2c3e50',
        textDecoration: 'none',
        fontSize: '14px',
    },
    footerSeparator: {
        margin: '0 10px',
        color: '#7f8c8d',
    },
    error: {
        color: 'red',
        marginBottom: '15px',
        textAlign: 'center',
    },
};

export default Login;
