import React, { useState, useEffect, useRef } from 'react';  
import Top from './Top';
import axios from 'axios';
import { FaSearch } from 'react-icons/fa';
import { MapContainer, TileLayer, Marker, Polyline, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

function Results() {
    const [reports, setReports] = useState([]);
    const [basePoints, setBasePoints] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortDirection, setSortDirection] = useState({ column: 'date', direction: 'asc' });
    const [selectedReport, setSelectedReport] = useState(null);
    const mapRef = useRef(null);

    useEffect(() => {
        fetchReports();
        fetchBasePoints();
    }, []);

    const fetchReports = async () => {
        try {
            const response = await axios.get('/api/get-reports');
            console.log('Fetched Reports:', response.data);
            setReports(response.data);
        } catch (error) {
            console.error('Error fetching reports:', error);
        }
    };

    const fetchBasePoints = async () => {
        try {
            const response = await axios.get('/api/get-base-points');
            console.log('Fetched Base Points:', response.data);
            setBasePoints(response.data);
        } catch (error) {
            console.error('Error fetching base points:', error);
        }
    };

    const markerIcon = new L.Icon({
        iconUrl: '/fibremarker.png',
        iconSize: [30, 38],
        iconAnchor: [12, 38],
    });

    function CustomAttribution() {
        const map = useMap();

        useEffect(() => {
            const logoControl = L.control({ position: 'bottomright' });
            logoControl.onAdd = function () {
                const div = L.DomUtil.create('div', 'custom-attribution');
                div.innerHTML = `<img src="/smartmaplogotext.png" alt="Logo" style="height: 20px;"/>`;
                return div;
            };
            logoControl.addTo(map);
            return () => {
                map.removeControl(logoControl);
            };
        }, [map]);

        return null;
    }

    const extractOriginCoordinates = (fileName) => {
        const regex = /Wireless--(-?\d+\.\d+),(-?\d+\.\d+)/;
        const match = fileName.match(regex);

        if (match) {
            let lat = parseFloat(match[1]);
            let lng = parseFloat(match[2]);
            if (lat > 0) {
                lat = -Math.abs(lat);
            }
            return { lat, lng };
        } else {
            return null;
        }
    };

    const findBaseStationCoordinates = (baseStationName) => {
        const basePoint = basePoints.find(point => point.name === baseStationName);
        if (basePoint) {
            return { lat: parseFloat(basePoint.latitude), lng: parseFloat(basePoint.longitude) };
        } else {
            return null;
        }
    };

    const handlePlotReport = (report) => {
        const originCoords = extractOriginCoordinates(report.file_name);
        const baseStationCoords = findBaseStationCoordinates(report.base_station_name);

        if (originCoords && baseStationCoords) {
            setSelectedReport({
                origin: originCoords,
                baseStation: baseStationCoords,
            });
        }
    };

    const handleViewReport = (fileName) => {
        const fileUrl = `/uploads/${encodeURIComponent(fileName)}`;
        window.open(fileUrl, '_blank');
    };

    const closeModal = () => {
        setSelectedReport(null);
    };

    const handleSort = (column) => {
        const newDirection = sortDirection.column === column && sortDirection.direction === 'asc' ? 'desc' : 'asc';
        setSortDirection({ column, direction: newDirection });

        const sortedReports = [...reports].sort((a, b) => {
            if (column === 'date') {
                return newDirection === 'asc'
                    ? new Date(a.date) - new Date(b.date)
                    : new Date(b.date) - new Date(a.date);
            } else if (column === 'sanitizedAddress') {
                return newDirection === 'asc'
                    ? a.sanitizedAddress.localeCompare(b.sanitizedAddress)
                    : b.sanitizedAddress.localeCompare(a.sanitizedAddress);
            }
            return 0;
        });

        setReports(sortedReports);
    };

    const filteredReports = reports.filter(report =>
        report.origin_address?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div style={styles.pageContainer}>
            <Top />
            <div style={styles.container}>
                <h2 style={styles.heading}>Reports</h2>
                <div style={styles.searchBoxContainer}>
                    <input
                        type="text"
                        placeholder="Search by address"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={styles.searchInput}
                    />
                    <FaSearch style={styles.searchIcon} />
                </div>
                <div style={styles.tableContainer}>
                    <table style={styles.table}>
                        <thead>
                            <tr>
                                <th style={styles.th} onClick={() => handleSort('date')}>
                                    Date {sortDirection.column === 'date' && (sortDirection.direction === 'asc' ? '▲' : '▼')}
                                </th>
                                <th style={styles.th} onClick={() => handleSort('sanitizedAddress')}>
                                    Address {sortDirection.column === 'sanitizedAddress' && (sortDirection.direction === 'asc' ? '▲' : '▼')}
                                </th>
                                <th style={styles.th}>Base Station</th>
                                <th style={styles.th}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredReports.length > 0 ? (
                                filteredReports.map((report, index) => {
                                    const parsedDate = report.report_date ? new Date(report.report_date).toLocaleDateString() : 'N/A';
                                    return (
                                        <tr key={index}>
                                            <td style={styles.td}>{parsedDate}</td>
                                            <td style={styles.td}>{report.origin_address || 'N/A'}</td>
                                            <td style={styles.td}>{report.base_station_name || 'N/A'}</td>
                                            <td style={styles.td}>
                                                <button style={styles.openButton} onClick={() => handleViewReport(report.file_name)}>Download</button>
                                                <button style={styles.plotButton} onClick={() => handlePlotReport(report)}>Show On Map</button>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="4" style={styles.td}>No reports found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {selectedReport && (
                    <div className="modal-overlay" onClick={closeModal}>
                        <div className="modal-content" style={styles.modal}>
                            <h3>Link Map</h3>
                            <MapContainer
                                center={selectedReport.origin}
                                zoom={13}
                                scrollWheelZoom={true}
                                style={styles.mapContainer}
                                ref={mapRef}
                                attributionControl={false}
                            >
                                <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" />
                                <Marker position={selectedReport.origin} icon={markerIcon} />
                                <Marker position={selectedReport.baseStation} icon={markerIcon} />
                                <Polyline positions={[selectedReport.origin, selectedReport.baseStation]} color="red" weight={1} />
                                <CustomAttribution />
                            </MapContainer>
                            <button style={styles.closeButton} onClick={closeModal}>Close</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

const styles = {
    pageContainer: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    container: {
        flexGrow: 1,
        margin: '5%',
        border: '1px solid slategray',
        borderRadius: '12px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column', // Ensure container grows vertically
        overflow: 'hidden', // Ensure content stays within the border
    },
    heading: {
        fontSize: '24px',
        marginBottom: '20px',
    },
    searchBoxContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
    },
    searchInput: {
        flex: 1,
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid slategray',
    },
    searchIcon: {
        marginLeft: '10px',
        color: 'gray',
    },
    tableContainer: {
        flexGrow: 1, // Allow the table to take up remaining space
        overflowY: 'auto', // Ensure the content is scrollable
        maxHeight: '400px', // Adjust the height to control the max table height within the container
        paddingRight: '15px', // Add padding to avoid scrollbar covering content
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginBottom: '20px',
    },
    th: {
        backgroundColor: '#f2f2f2',
        borderBottom: '1px solid slategray',
        padding: '10px',
        cursor: 'pointer',
    },
    td: {
        borderBottom: '1px solid slategray',
        padding: '10px',
        textAlign: 'center',
    },
    openButton: {
        padding: '8px 12px',
        borderRadius: '5px',
        backgroundColor: '#61acb4',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
        marginRight: '10px',
    },
    plotButton: {
        padding: '8px 12px',
        borderRadius: '5px',
        backgroundColor: '#ff7043',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
    },
    mapContainer: {
        width: '100%',
        height: '600px',
    },
    modal: {
        width: '80%',
        height: '90vh',
        position: 'fixed',
        top: '5%',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '12px',
        zIndex: 2000,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    closeButton: {
        marginTop: '20px',
        padding: '10px',
        borderRadius: '6px',
        backgroundColor: '#61acb4',
        color: 'white',
        cursor: 'pointer',
        border: 'none',
        alignSelf: 'center',
    },
};

export default Results;
