import React, { useState, useEffect } from 'react';
import Top from './Top';

function Users() {
    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState({ username: '', email: '', name: '', password: '', role: 'user' });
    const [editUser, setEditUser] = useState(null);
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await fetch('/api/get-users');
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            console.error('Error fetching users:', error);
            setMessage('Error fetching users.');
        }
    };

    const handleAddUser = async () => {
        if (!newUser.username || !newUser.email || !newUser.name || !newUser.password) {
            setMessage('Please fill in all fields.');
            return;
        }

        try {
            const response = await fetch('/api/add-user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newUser),
            });

            if (response.ok) {
                setMessage('User added successfully.');
                setNewUser({ username: '', email: '', name: '', password: '', role: 'user' });
                fetchUsers();
            } else {
                const errorText = await response.text();
                setMessage(`Error: ${errorText}`);
            }
        } catch (error) {
            console.error('Error adding user:', error);
            setMessage('Error adding user.');
        }
    };

    const handleDeleteUser = async (id) => {
        if (!window.confirm('Are you sure you want to delete this user?')) {
            return;
        }

        try {
            const response = await fetch(`/api/delete-user/${id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                setMessage('User deleted successfully.');
                fetchUsers();
            } else {
                const errorText = await response.text();
                setMessage(`Error: ${errorText}`);
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            setMessage('Error deleting user.');
        }
    };

    const handleEditUser = (user) => {
        setEditUser(user);
        setPassword('');
    };

    const handleSaveEdit = async (id) => {
        try {
            const response = await fetch(`/api/edit-user/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editUser),
            });

            if (response.ok) {
                setMessage('User updated successfully.');
                setEditUser(null);
                fetchUsers();
            } else {
                const errorText = await response.text();
                setMessage(`Error: ${errorText}`);
            }
        } catch (error) {
            console.error('Error updating user:', error);
            setMessage('Error updating user.');
        }
    };

    const handlePasswordChange = async (id) => {
        if (!password) {
            setMessage('Please enter a new password.');
            return;
        }

        try {
            const response = await fetch(`/api/change-password/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ password }),
            });

            if (response.ok) {
                setMessage('Password changed successfully.');
                setPassword('');
            } else {
                const errorText = await response.text();
                setMessage(`Error: ${errorText}`);
            }
        } catch (error) {
            console.error('Error changing password:', error);
            setMessage('Error changing password.');
        }
    };

    const handleChange = (e) => {
        setEditUser({
            ...editUser,
            [e.target.name]: e.target.value,
        });
    };

    const handleNewUserChange = (e) => {
        setNewUser({
            ...newUser,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div style={styles.pageContainer}>
            <Top />
            <div style={styles.container}>
                <div style={styles.leftSection}>
                    <h3 style={styles.heading}>Add New User</h3>
                    <input
                        type="text"
                        placeholder="Username"
                        name="username"
                        value={newUser.username}
                        onChange={handleNewUserChange}
                        style={styles.input}
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={newUser.email}
                        onChange={handleNewUserChange}
                        style={styles.input}
                    />
                    <input
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={newUser.name}
                        onChange={handleNewUserChange}
                        style={styles.input}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={newUser.password}
                        onChange={handleNewUserChange}
                        style={styles.input}
                    />
                    <select name="role" value={newUser.role} onChange={handleNewUserChange} style={styles.input}>
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                    </select>
                    <button onClick={handleAddUser} style={styles.addButton}>
                        Add User
                    </button>
                    {message && <p>{message}</p>}
                </div>

                <div style={styles.verticalLine}></div>

                <div style={styles.rightSection}>
                    <h3 style={styles.heading}>Manage Users</h3>
                    <table style={styles.table}>
                        <thead>
                            <tr>
                                <th style={styles.th}>Username</th>
                                <th style={styles.th}>Email</th>
                                <th style={styles.th}>Name</th>
                                <th style={styles.th}>Role</th>
                                <th style={styles.th}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr key={user.id}>
                                    {editUser && editUser.id === user.id ? (
                                        <>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="username"
                                                    value={editUser.username}
                                                    onChange={handleChange}
                                                    style={styles.input}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={editUser.email}
                                                    onChange={handleChange}
                                                    style={styles.input}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={editUser.name}
                                                    onChange={handleChange}
                                                    style={styles.input}
                                                />
                                            </td>
                                            <td>
                                                <select name="role" value={editUser.role} onChange={handleChange} style={styles.input}>
                                                    <option value="user">User</option>
                                                    <option value="admin">Admin</option>
                                                </select>
                                            </td>
                                            <td>
                                                <button onClick={() => handleSaveEdit(user.id)} style={styles.saveButton}>
                                                    Save
                                                </button>
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <td style={styles.td}>{user.username}</td>
                                            <td style={styles.td}>{user.email}</td>
                                            <td style={styles.td}>{user.name}</td>
                                            <td style={styles.td}>{user.role}</td>
                                            <td style={styles.td}>
                                                <button onClick={() => handleEditUser(user)} style={styles.editButton}>
                                                    Edit
                                                </button>
                                                <button onClick={() => handleDeleteUser(user.id)} style={styles.deleteButton}>
                                                    Delete
                                                </button>
                                                <button onClick={() => handlePasswordChange(user.id)} style={styles.passwordButton}>
                                                    Change Password
                                                </button>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

const styles = {
    pageContainer: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    container: {
        display: 'flex',
        flexGrow: 1,
        margin: '5%',
        border: '1px solid slategray',
        borderRadius: '12px',
        overflow: 'hidden',
        height: 'calc(100vh - 20vh - 10%)',
    },
    leftSection: {
        flexBasis: '25%',
        paddingRight: '20px',
        overflowY: 'auto',
        maxHeight: '100%',
        paddingLeft: '10px',
    },
    verticalLine: {
        width: '1px',
        backgroundColor: 'slategray',
        marginLeft: '10px',
        marginRight: '10px',
    },
    rightSection: {
        flexBasis: '75%',
        paddingLeft: '20px',
        overflowY: 'auto',
        maxHeight: '100%',
    },
    heading: {
        fontSize: '18px',
        color: '#2c3e50',
        marginBottom: '15px',
    },
    input: {
        padding: '8px',
        margin: '5px',
        border: '1px solid #61acb4',
        borderRadius: '4px',
    },
    addButton: {
        backgroundColor: '#61acb4',
        color: '#fff',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginBottom: '20px',
    },
    th: {
        backgroundColor: '#f2f2f2',
        borderBottom: '1px solid slategray',
        padding: '10px',
    },
    td: {
        borderBottom: '1px solid slategray',
        padding: '10px',
        textAlign: 'center',
    },
    saveButton: {
        backgroundColor: '#5cb85c',
        color: '#fff',
        padding: '5px 10px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    editButton: {
        backgroundColor: '#61acb4',
        color: '#fff',
        padding: '5px 10px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginRight: '5px',
    },
    deleteButton: {
        backgroundColor: '#d9534f',
        color: '#fff',
        padding: '5px 10px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    passwordButton: {
        backgroundColor: '#f0ad4e',
        color: '#fff',
        padding: '5px 10px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
};

export default Users;
