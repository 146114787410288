// src/components/Mobile.js
import React from 'react';
import Top from './Top';

function Mobile() {
    return (
        <div style={styles.pageContainer}>
            <Top />
            <div style={styles.container}>
                <div style={styles.leftSection}>
                    <h2>Left Section</h2>
                    <p>This section is scrollable. It takes up 25% of the page.</p>
                    <p>Additional content for testing scrollability...</p>
                    <p>Additional content for testing scrollability...</p>
                    <p>Additional content for testing scrollability...</p>
                    <p>Additional content for testing scrollability...</p>
                </div>
                <div style={styles.verticalLine}></div> {/* Slate grey line */}
                <div style={styles.rightSection}>
                    <h2>Right Section</h2>
                    <p>This section is scrollable. It takes up 75% of the page.</p>
                    <p>Additional content for testing scrollability...</p>
                    <p>Additional content for testing scrollability...</p>
                    <p>Additional content for testing scrollability...</p>
                    <p>Additional content for testing scrollability...</p>
                </div>
            </div>
        </div>
    );
}

const styles = {
    pageContainer: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden', // Prevents page from scrolling
    },
    container: {
        display: 'flex',
        flexGrow: 1,  // Takes the remaining space after the top section
        margin: '5%',
        border: '1px solid slategray',
        borderRadius: '12px',
        overflow: 'hidden',  // Prevent content overflow
        height: 'calc(100vh - 20vh - 10%)',  // Adjust height to account for the top and margins
    },
    leftSection: {
        flexBasis: '25%',  // 25% width
        paddingRight: '20px',
        overflowY: 'auto',  // Enable vertical scrolling within this section
        maxHeight: '100%',  // Ensure content stays within the border
        paddingLeft: '10px',
    },
    verticalLine: {
        width: '1px',
        backgroundColor: 'slategray',
        marginLeft: '10px',
        marginRight: '10px',
    },
    rightSection: {
        flexBasis: '75%',  // 75% width
        paddingLeft: '20px',
        overflowY: 'auto',  // Enable vertical scrolling within this section
        maxHeight: '100%',  // Ensure content stays within the border
    },
};


export default Mobile;
