const frogfootConfig = {
    apiUrl: 'https://isp-test.frogfoot.com/frogfoot/rest/api/frogfootfttx/v1/feasibility',
    frogfootApiKey: 'NjUqM3RROlp2ZFJEOHJhMVw=', // Base64 encoded Frogfoot API key
    googleApiKey: 'AIzaSyAuC39UoplUhw-bFf1kcBegnm7j5RUvHhg', // Replace with clients actual Google API key
    ftthlink: 'https://www.konektsp.co.za', // Static FTTH link to sales page
    fttblink: 'https://www.konektsp.co.za', // Static FTTB link to sales page
    logo: './fflogo.png', // Path to the Frogfoot logo
};

export default frogfootConfig;
